import React from 'react';
import './gallery.css';

const Gallery = (props) => {
  return (
      <div onClick={props.openPhotography} className="singleGallery">
        <div className={props.naslovKlasa}>
          <h3>{props.naslov}</h3>
          <p>{props.brSlika}</p>
          <img className="plus_icon" src="http://lanapavkov.com/content/more.png" alt="open" />
        </div>
        <img className={props.slikaKlasa} src={props.naslovnaSlika} alt="nova slika "/>
      </div>
  )
}

export default Gallery;
