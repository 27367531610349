import React from 'react';
import './film.css';

const Film = (props) => {
  return (
      <div onClick={props.openFilm} className="singleMovie">
        <div className={props.naslovKlasa}>
          <h3>{props.naslov}</h3>
          <p>{props.godina}</p>
          <img className="plus_icon" src="http://lanapavkov.com/content/more.png" alt="open" />
        </div>
        <img className={props.slikaKlasa} src={props.naslovnaSlika} alt="nova slika "/>
      </div>
  )
}

export default Film;
