import React from 'react';
import './containerPhotography.css';
import ImageLoopPhoto from '../../components/ImageLoopPhoto/ImageLoopPhoto';

const ContainerPhotography = (props) => {
  return (
    <div className={props.klasa.join(' ')}>
    <div className="arrow_holder">
      <p className="br_slike">{props.brSlike}/{props.selectedPhotography && props.selectedPhotography.slikeUGaleriji && props.selectedPhotography.slikeUGaleriji.length}</p>
      <img onClick={props.klikLevo} src="http://lanapavkov.com/content/back_arrow.png" alt="right_arrow" />
      <img onClick={props.klikDesno} src="http://lanapavkov.com/content/back_arrow.png" alt="left_arrow" />
    </div>
    <div className="horizontal_container" style={{ left: props.left }}>
    {props.selectedPhotography && props.selectedPhotography.slikeUGaleriji && props.selectedPhotography.slikeUGaleriji.map((slika, index)=> {
             return <ImageLoopPhoto Key={'Key-'+index} url={slika} />
     })}
    </div>
      <img onClick={props.zatvori} src="http://lanapavkov.com/content/close.png" alt="close" className="close-popup" />
    </div>
  )
}

export default ContainerPhotography;
