import React from 'react';
import './navigation.css';

const Navigation = (props) => {
  return (
      <div className={props.navKlasa.join(' ')}>
        <p onClick={props.rotationFilm} className="film_nav">Film</p>
        <p onClick={props.rotationPhotography} className="photography_nav">Photography</p>
        <p onClick={props.rotationTheatre} className="theatre_nav">Theatre</p>
        <p onClick={props.rotationAwards} className="awards_nav">Festivals</p>
      </div>
  )
}

export default Navigation;
