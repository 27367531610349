import React from 'react';
import './theatre.css';

const Theatre = (props) => {
  return (
      <div onClick={props.openTheatre} className="theatre">
        <div className={props.naslovKlasa}>
          <h3>{props.naslov}</h3>
          <p>{props.godina}</p>
          <img className="plus_icon" src="http://lanapavkov.com/content/more.png" alt="open" />
        </div>
        <img className={props.slikaKlasa} src={props.naslovnaSlika} alt="nova slika "/>
      </div>
  )
}

export default Theatre;
