import React from 'react';
import './containerMG.css';

const ContainerMG = (props) => {
  return (
      <div className={props.klasa.join(' ')}>
        <video className="videotag" autoPlay loop muted src={props.selectedFilm.linkVideo} type="video/mp4" />
        <div className="text_segment">
          <h4>{props.selectedFilm.naslovFilma}</h4>
          <div className="links_video">
           <a href="https://www.imdb.com/title/tt10039624/" target="_blank" className="imdb"><i className="fab fa-imdb"></i></a>
           <a href="https://vimeo.com/315606985" target="_blank" className="vimeo"><i className="fab fa-vimeo-v"></i></a>
          </div>
        </div>
        { props.selectedFilm.naslov === "19 Schaffarik Street" &&
          <p onClick={props.openFilmGallery} className="gallery_film">
            <i>Info & image gallery >>> </i>
          </p>
        }
        <img onClick={props.zatvori} src="http://lanapavkov.com/content/close_w.png" alt="close" className="close-popup" />
      </div>
  )
}

export default ContainerMG;
