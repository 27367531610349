import React from 'react';
import './imageLoop.css';

const ImageLoop = (props) => {
  return (
      <div className="horizontal_element" key={props.url}>
        <img src={props.url} alt="alt"/>
      </div>
  )
}

export default ImageLoop;
