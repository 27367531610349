import React from 'react';
import './containerTheatre.css';
import ImageLoop from '../../components/ImageLoop/ImageLoop';

const ContainerTheatre = (props) => {
  return (
      <div className={props.klasa.join(' ')}>
      <div className="arrow_holder">
      <p className="br_slike">{props.brSlike}/{props.selectedTheatre && props.selectedTheatre.nazivGalerije && props.selectedTheatre.nazivGalerije.length}</p>
        <img onClick={props.klikLevo} src="http://lanapavkov.com/content/back_arrow.png" alt="right_arrow" />
        <img onClick={props.klikDesno} src="http://lanapavkov.com/content/back_arrow.png" alt="left_arrow" />
      </div>
        <div className="horizontal_container" style={{ left: props.left }}>
        <div className="horizontal_element">
          <p className="opis_theatre">{props.selectedTheatre.opis}</p>
          { props.selectedTheatre.naslov === "Feeling beardy" &&
            <p className="opis_theatre">Atelje 212, Belgrade</p>
          }
          <div className="horizontalBox">
            <div className="roles_director">
            <p className="rolesH"><b>Roles/</b></p>
            {props.selectedTheatre && props.selectedTheatre.roles && props.selectedTheatre.roles.map((uloga, index)=> {
                     return <p className="uloga">{uloga}</p>
             })}
            </div>
            <div className="roles_director">
            {props.selectedTheatre && props.selectedTheatre.director && props.selectedTheatre.director.map((director, index)=> {
                     return <p className="uloga">{director}</p>
             })}
            </div>
          </div>
          <p className="use_gallery"><i>use arrows to see the gallery</i></p>
        </div>
        {props.selectedTheatre && props.selectedTheatre.nazivGalerije && props.selectedTheatre.nazivGalerije.map((slika, index)=> {
                 return <ImageLoop Key={'Key-'+index} url={slika} />
         })}
        </div>
        <img onClick={props.zatvori} src="http://lanapavkov.com/content/close.png" alt="close" className="close-popup" />
      </div>
  )
}

export default ContainerTheatre;
