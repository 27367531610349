import React, { Component } from 'react';
import './App.css';
import Film from './components/Film/Film';
import Gallery from './components/Gallery/Gallery';
import ContainerMG from './components/ContainerMG/ContainerMG';
import AboutContact from './components/AboutContact/AboutContact';
import Navigation from './components/Navigation/Navigation';
import Theatre from './components/Theatre/Theatre';
import Awards from './components/Awards/Awards';
import ContainerPhotography from './components/ContainerPhotography/ContainerPhotography';
import ContainerFilmGallery from './components/ContainerFilmGallery/ContainerFilmGallery';
import ContainerTheatre from './components/ContainerTheatre/ContainerTheatre';

class App extends Component {
  state = {
    filmovi: [{
        "naslov": "19 Schaffarik Street",
        "id": "00",
      	"naslovFilma": "19 Schaffarik Street",
      	"godina": "2019.",
      	"opis": "Vanya and Anya are dinning with their guests Sergei, Boris and Ivana in an elegantly furnished salon, located in a semi-ruined house. Vanya concludes that the house they are dinning in seems very familiar to her, while Anya also has the same conclusion. Soon, Vanya realizes that Anya is her daughter and that the house in which they are dinning is theirs. Everything becomes even more peculiar when Vanya pays attention to her guest. She can not understand how could she spend some much money on such an expensive dinner, when she has none. To make things worse, musicians come to her house…",
      	"naslovnaSlika": "http://lanapavkov.com/content/safarikova.png",
      	"linkVideo": "http://lanapavkov.com/content/video/Sequence01_1.mp4",
      	"externiLink": "http://www.vimeo.com/video.mp4",
        "naslovKlasa": "safarikova_naslov",
        "credits": ["Director_Lana Pavkov", "Screenplay_Lana Pavkov, Dejan Prćić", "Producers_Vanja Lozanović, Ena Bajraktarević, Tijana Drakulić", "Director of photography_ Luka Trajković", "Editor_Tara Vulović", "Composer_Miroslav Bako", "Art director_Lana Pavkov", "Production designer_Una Jankov, Daniela Dimitrovska", "Costume designer_Biljana Grgur", "Sound designer_Miroslav Bako", "Graphic Design_Dragana Krtinić"],
        "cast": ["Vanya_Jasna Đuričić", "Anya_Jovana Gavrilović", "Ivana_Sunčica Milanović", "Sergei_Ivan Marković", "Boris_Deneš Debrei", "Drummer_Andrija Bošković", "Violoncellist_Eva Pavkov", "Pianist_Hana Jugović", "Singer_Kosta Jugović", "Saxophonist_Aleksandar Van Der Zijden", "Coloring_Teleking", "Credits_Fabrika"],
        "slikeUGaleriji": ["http://lanapavkov.com/content/safarikova_img/1.jpg", "http://lanapavkov.com/content/safarikova_img/2.jpg",  "http://lanapavkov.com/content/safarikova_img/3.jpg", "http://lanapavkov.com/content/safarikova_img/4.jpg", "http://lanapavkov.com/content/safarikova_img/5.jpg", "http://lanapavkov.com/content/safarikova_img/6.jpg", "http://lanapavkov.com/content/safarikova_img/7.jpg", "http://lanapavkov.com/content/safarikova_img/8.jpg", "http://lanapavkov.com/content/safarikova_img/9.jpg", "http://lanapavkov.com/content/safarikova_img/10.jpg"],
        "slikaKlasa": "safarikova_slika"
      }
    ],
    fotografije: [
      {
        "naslov": "Sculptures",
        "id": "f00",
        "brSlika": "10 image Gallery",
        "naslovnaSlika": "http://lanapavkov.com/content/img1.png",
        "naslovKlasa": "sculptures_naslov",
        "slikeUGaleriji": ["http://lanapavkov.com/content/skulpture/skp0.jpg", "http://lanapavkov.com/content/skulpture/skp1.jpg",  "http://lanapavkov.com/content/skulpture/skp2.jpg", "http://lanapavkov.com/content/skulpture/skp3.jpg", "http://lanapavkov.com/content/skulpture/skp4.jpg", "http://lanapavkov.com/content/skulpture/skp5.jpg", "http://lanapavkov.com/content/skulpture/skp6.jpg", "http://lanapavkov.com/content/skulpture/skp7.jpg", "http://lanapavkov.com/content/skulpture/skp8.jpg", "http://lanapavkov.com/content/skulpture/skp9.jpg"],
        "slikaKlasa": "sculptures"
      }, {
        "naslov": "Captions",
        "id": "f01",
        "brSlika": "16 image Gallery",
        "naslovnaSlika": "http://lanapavkov.com/content/una_gallery.png",
        "naslovKlasa": "captions_naslov",
        "slikeUGaleriji": ["http://lanapavkov.com/content/captions/cpt1.jpg", "http://lanapavkov.com/content/captions/cpt2.jpg","http://lanapavkov.com/content/captions/cpt3.jpg", "http://lanapavkov.com/content/captions/cpt4.jpg", "http://lanapavkov.com/content/captions/cpt5.jpg", "http://lanapavkov.com/content/captions/cpt6.jpg", "http://lanapavkov.com/content/captions/cpt7.jpg", "http://lanapavkov.com/content/captions/cpt8.jpg", "http://lanapavkov.com/content/captions/cpt9.jpg", "http://lanapavkov.com/content/captions/cpt10.jpg", "http://lanapavkov.com/content/captions/cpt11.jpg", "http://lanapavkov.com/content/captions/cpt14.jpg", "http://lanapavkov.com/content/captions/cpt15.jpg", "http://lanapavkov.com/content/captions/cpt16.jpg",],
        "slikaKlasa": "captions"
      }, {
        "naslov": "Eva",
        "id": "f02",
        "brSlika": "9 image Gallery",
        "naslovnaSlika": "http://lanapavkov.com/content/Eva_prez.png",
        "naslovKlasa": "eva_naslov",
        "slikeUGaleriji": ["http://lanapavkov.com/content/eva/1.jpg", "http://lanapavkov.com/content/eva/2.jpg", "http://lanapavkov.com/content/eva/3.jpg", "http://lanapavkov.com/content/eva/4.jpg", "http://lanapavkov.com/content/eva/4(1).jpg", "http://lanapavkov.com/content/eva/5.jpg", "http://lanapavkov.com/content/eva/6.jpg", "http://lanapavkov.com/content/eva/7.jpg", "http://lanapavkov.com/content/eva/8.jpg", "http://lanapavkov.com/content/eva/10.jpg", "http://lanapavkov.com/content/eva/11.jpg", "http://lanapavkov.com/content/eva/12.jpg", "http://lanapavkov.com/content/eva/13.jpg", "http://lanapavkov.com/content/eva/14.JPG", "http://lanapavkov.com/content/eva/15.JPG", "http://lanapavkov.com/content/eva/16.jpg", "http://lanapavkov.com/content/eva/17.jpg", "http://lanapavkov.com/content/eva/18.jpg", "http://lanapavkov.com/content/eva/19.jpg", "http://lanapavkov.com/content/eva/20.jpg", "http://lanapavkov.com/content/eva/21.jpg", "http://lanapavkov.com/content/eva/22.jpg", "http://lanapavkov.com/content/eva/23.jpg", "http://lanapavkov.com/content/eva/24.jpg"],
        "slikaKlasa": "eva"
      }, {
        "naslov": "Exposures",
        "id": "f03",
        "brSlika": "19 image Gallery",
        "naslovnaSlika": "http://lanapavkov.com/content/street_gallery_1.png",
        "naslovKlasa": "exposures_naslov",
        "slikeUGaleriji": ["http://lanapavkov.com/content/exposures/exp1.JPG", "http://lanapavkov.com/content/exposures/exp2.JPG", "http://lanapavkov.com/content/exposures/exp3.JPG", "http://lanapavkov.com/content/exposures/exp4.JPG", "http://lanapavkov.com/content/exposures/exp5.JPG", "http://lanapavkov.com/content/exposures/exp6.JPG", "http://lanapavkov.com/content/exposures/exp7.JPG", "http://lanapavkov.com/content/exposures/exp8.JPG", "http://lanapavkov.com/content/exposures/exp9.JPG", "http://lanapavkov.com/content/exposures/exp10.JPG", "http://lanapavkov.com/content/exposures/exp13.JPG", "http://lanapavkov.com/content/exposures/exp14.JPG", "http://lanapavkov.com/content/exposures/exp15.JPG", "http://lanapavkov.com/content/exposures/exp16.JPG", "http://lanapavkov.com/content/exposures/exp17.JPG", "http://lanapavkov.com/content/exposures/exp18.JPG", "http://lanapavkov.com/content/exposures/exp19.JPG", "http://lanapavkov.com/content/exposures/exp20.JPG", "http://lanapavkov.com/content/exposures/exp21.JPG", "http://lanapavkov.com/content/exposures/exp22.JPG", "http://lanapavkov.com/content/exposures/exp23.JPG", "http://lanapavkov.com/content/exposures/exp24.JPG", "http://lanapavkov.com/content/exposures/exp25.JPG", "http://lanapavkov.com/content/exposures/exp26.jpg", "http://lanapavkov.com/content/exposures/exp27.jpg", "http://lanapavkov.com/content/exposures/exp28.jpg", "http://lanapavkov.com/content/exposures/exp29.jpg", "http://lanapavkov.com/content/exposures/exp30.jpg"],
        "slikaKlasa": "exposures"
      }
    ],
    theatre: [
      {
        "naslov": "Dear Elena Sergeevna",
        "id": "t00",
        "godina": "2015.",
        "opis": "The graduates come to their professor of math, Elena Sergeevna, to congratulate her birthday. The real reason why they visited her is the key of the safe where their graduate exams are being kept. Four students stay with the professor until morning, committing psychological violence, in an attempt to force her to surrender the key for the safe so they can correct their exams. When the violence peaks in the final scene Elena Sergeevna throws the key and runs into the bathroom in complete devastation and fury. The three boys run out of their apartment, afraid of the consequences. Lyalya tries to reach Elena, knocking on the bathroom doors, but no one is answering...",
        "naslovnaSlika": "http://lanapavkov.com/content/djs_prez.png",
        "naslovKlasa": "sergeevna_naslov",
        "roles": ["Elena_Vera Hrćan Ostojić", "Sonya_Sonja Isailović", "Marko_Marko Vasiljević", "Aljoša_Aljoša Đidić", "Stefan_Stefan Beronja"],
        "director": ["Written by_Lydmila Razumovskaya", "Director_ Lana Pavkov", "Dramaturgy_Lana Pavkov, Tijana Grumić", "Scene design_Una Jankov", "Costume design_Lana Pavkov, Una Jankov, Marko Marosiuk", "Photography_Srđan Đurić"],
        "nazivGalerije": ["http://lanapavkov.com/content/djs/djs1.jpg", "http://lanapavkov.com/content/djs/djs2.jpg", "http://lanapavkov.com/content/djs/djs3.jpg", "http://lanapavkov.com/content/djs/djs4.jpg", "http://lanapavkov.com/content/djs/djs5.jpg", "http://lanapavkov.com/content/djs/djs6.jpg"],
        "slikaKlasa": "sergeevna"
      }, {
        "naslov": "Feeling beardy",
        "id": "t01",
        "godina": "2017.",
        "opis": "Moscowian journalist Marina visits a very peculiar village in Southern Russia, where she meets even more peculiar people.",
        "naslovnaSlika": "http://lanapavkov.com/content/Osecaj-brade_prez.png",
        "naslovKlasa": "osecajbrade_naslov",
        "roles": ["Jovana Gavrilović", "Tamara Dragičević", "Miodrag Dragičević", "Vladislav Mihailović", "Jovan Belobrković"],
        "director": ["Written by_Ksenia Dragunskaya", "Director_ Mia Knežević", "Directors assistent_Lana Pavkov", "Dramaturgy_Dimitrije Kokanov", "Scene Design_ Daniela Dimitrovska", "Scene Design assistent_Una Jankov", "Costume Desgin_Biljana Grgur"],
        "nazivGalerije": ["http://lanapavkov.com/content/osecajbrade/1.JPG", "http://lanapavkov.com/content/osecajbrade/2.JPG", "http://lanapavkov.com/content/osecajbrade/3.JPG", "http://lanapavkov.com/content/osecajbrade/4.JPG", "http://lanapavkov.com/content/osecajbrade/5.JPG", "http://lanapavkov.com/content/osecajbrade/6.JPG"],
        "slikaKlasa": "osecajbrade"
      }
    ],
    awards: [
      {
        "naslov": "Zagreb Film Festival / Best Pitch / ŠAFARIKOVA 19",
        "id": "a00",
        "godina": "2015.",
        "naslovKlasa": "zagreb"
      }, {
        "naslov": "Yugoslav Film Archive / Best film / WORLD CHAMPION",
        "id": "a01",
        "godina": "2017.",
        "naslovKlasa": "yfa"
      }
    ],
    galerija1: ["http://lanapavkov.com/content/skulpture/skp4.jpg", "http://lanapavkov.com/content/skulpture/skp3.jpg", "http://lanapavkov.com/content/skulpture/skp5.jpg", "http://lanapavkov.com/content/skulpture/skp0.jpg"],
    prikaziFilmCont: false,
    updown: ["containerMG"],
    updownPhotography: ["container_photography"],
    updownFilmGallery: ["container_FilmGallery"],
    updownTheatre: ["container_theatre"],
    contactAnim: ["contact_container"],
    arrowAnim: ["arrow_contact"],
    contactbtnAnim: ["contactbtn"],
    aboutContact: ["about_contact"],
    whiteCanvasAnim: ["whiteCanvas"],
    aboutAnim: ["about_segment"],
    navKlasa: ["navigation", "rot-film"],
    navRotation: ["movies-container", "movies-container-film"],
    infoMobile: ["fas", "fa-info-circle", "info_mobile"],
    selectedFilm: {},
    selectedTheatre: {},
    selectedPhotography: {},
    brslikeuG: 0,
    mobileThing: 0,
    left: 0
  }

  filmNav = () => {
    const {navRotation} = this.state;
    navRotation.pop();
    navRotation.push("movies-container-film");
    this.setState({navRotation: navRotation});

    const {navKlasa} = this.state;
    navKlasa.pop();
    navKlasa.push("rot-film");
    this.setState({navKlasa: navKlasa});
  }
  photographyNav = () => {
    const {navRotation} = this.state;
    navRotation.pop();
    navRotation.push("movies-container-photography");
    this.setState({navRotation: navRotation});

    const {navKlasa} = this.state;
    navKlasa.pop();
    navKlasa.push("rot-photography");
    this.setState({navKlasa: navKlasa});
  }
  theatreNav = () => {
    const {navRotation} = this.state;
    navRotation.pop();
    navRotation.push("movies-container-theatre");
    this.setState({navRotation: navRotation});

    const {navKlasa} = this.state;
    navKlasa.pop();
    navKlasa.push("rot-theatre");
    this.setState({navKlasa: navKlasa});
  }
  awardsNav = () => {
    const {navRotation} = this.state;
    navRotation.pop();
    navRotation.push("movies-container-awards");
    this.setState({navRotation: navRotation});

    const {navKlasa} = this.state;
    navKlasa.pop();
    navKlasa.push("rot-awards");
    this.setState({navKlasa: navKlasa});
  }

  openContact = () => {
    const {contactAnim} = this.state;
    contactAnim.push("contant_open");
    this.setState({contactAnim: contactAnim});

    const {aboutContact} = this.state;
    aboutContact.push("about_contact_animation");
    this.setState({aboutContact: aboutContact});

    const {arrowAnim} = this.state;
    arrowAnim.push("arrowAnim");
    this.setState({arrowAnim: arrowAnim});

    const {contactbtnAnim} = this.state;
    contactbtnAnim.push("contactbtnAnim");
    this.setState({contactbtnAnim: contactbtnAnim});

    const {whiteCanvasAnim} = this.state;
    whiteCanvasAnim.push("whiteCanvasAnim");
    this.setState({whiteCanvasAnim: whiteCanvasAnim});
  }

  closeContact = () => {
    const {contactAnim} = this.state;
    contactAnim.pop();
    this.setState({contactAnim: contactAnim});

    const {aboutContact} = this.state;
    aboutContact.pop();
    this.setState({aboutContact: aboutContact});

    const {arrowAnim} = this.state;
    arrowAnim.pop();
    this.setState({arrowAnim: arrowAnim});

    const {contactbtnAnim} = this.state;
    contactbtnAnim.pop();
    this.setState({contactbtnAnim: contactbtnAnim});

    const {whiteCanvasAnim} = this.state;
    whiteCanvasAnim.pop();
    this.setState({whiteCanvasAnim: whiteCanvasAnim});
  }

  openAbout = () => {
    const {aboutAnim} = this.state;
    aboutAnim.push("aboutAnim");
    this.setState({aboutAnim: aboutAnim});
  }
  closeAbout = () => {
    const {aboutAnim} = this.state;
    aboutAnim.pop();
    this.setState({aboutAnim: aboutAnim});
  }

  closePreview = () => {
    const {updown} = this.state;
    updown.pop();
    this.setState({updown: updown});
  }
  closePreviewPhotography = () => {
    const {updownPhotography} = this.state;
    updownPhotography.pop();
    this.setState({updownPhotography: updownPhotography});
    this.resetBrSlika();

  }
  closePreviewTheatre = () => {
    const {updownTheatre} = this.state;
    updownTheatre.pop();
    this.setState({updownTheatre: updownTheatre});
    this.resetBrSlika();
  }

  closePreviewFilmGallery = () => {
    const {updownFilmGallery} = this.state;
    updownFilmGallery.pop();
    this.setState({updownFilmGallery: updownFilmGallery});
    this.resetBrSlika();
  }

  injectFilm = (filmIndex) => {
    const {updown} = this.state;
    updown.push("position-top");
    this.setState({updown: updown});
    const selectedFilm = this.state.filmovi.find((film, index) => index === filmIndex)
    this.setState((prevState) => ({
       selectedFilm
    }))
  }

  openFilmGallery = () => {
    console.log("testolino");
    let {brslikeuG} = this.state;
    brslikeuG = 1;
    this.setState({brslikeuG: brslikeuG});

    const {updownFilmGallery} = this.state;
    updownFilmGallery.push("position-top-FilmGallery");
    this.setState({updownFilmGallery: updownFilmGallery});
  }

  injectPhotography = (fotografijaIndex) => {
    let {brslikeuG} = this.state;
    brslikeuG = 1;
    this.setState({brslikeuG: brslikeuG});

    const {updownPhotography} = this.state;
    updownPhotography.push("position-top-photography");
    this.setState({updownPhotography: updownPhotography});
    const selectedPhotography = this.state.fotografije.find((fotografija, index) => index === fotografijaIndex)
    this.setState((prevState) => ({
       selectedPhotography
    }))
  }

  injectTheatre = (theatreIndex) => {
    let {brslikeuG} = this.state;
    brslikeuG = 0;
    this.setState({brslikeuG: brslikeuG});

    const {updownTheatre} = this.state;
    updownTheatre.push("position-top-theatre");
    this.setState({updownTheatre: updownTheatre});
    const selectedTheatre = this.state.theatre.find((theatre, index) => index === theatreIndex)
    this.setState((prevState) => ({
       selectedTheatre
    }))
  }

  pomeranjeGalerijeDesno = () => {
    let {left} = this.state;
    let {brslikeuG} = this.state;
    if( brslikeuG >= 0 && brslikeuG <= this.state.selectedTheatre.nazivGalerije.length-1){
        brslikeuG += 1;
        this.setState({brslikeuG: brslikeuG});
        left -= window.innerWidth ;
        this.setState({left: left});
        console.log(this.state.selectedTheatre.nazivGalerije.length);
    }
    else {
      brslikeuG = 0;
      this.setState({brslikeuG: brslikeuG});
      left = 0 ;
      this.setState({left: left});
    }
  }

  pomeranjeGalerijeLevo = () => {
    let {left} = this.state;
    let {brslikeuG} = this.state;
    if( brslikeuG >= 1 && brslikeuG <= this.state.selectedTheatre.nazivGalerije.length){
        brslikeuG -= 1;
        this.setState({brslikeuG: brslikeuG});
        left += window.innerWidth ;
        this.setState({left: left});
    }
    else {
      brslikeuG = this.state.selectedTheatre.nazivGalerije.length;
      this.setState({brslikeuG: brslikeuG});
      left = -(window.innerWidth*(this.state.selectedTheatre.nazivGalerije.length)) ;
      this.setState({left: left});
    }
  }

  pomeranjeGalerijePhotoDesno = () => {
    let {left} = this.state;
    let {brslikeuG} = this.state;
    if( brslikeuG >= 1 && brslikeuG <= this.state.selectedPhotography.slikeUGaleriji.length-1){
        brslikeuG += 1;
        this.setState({brslikeuG: brslikeuG});
        left -= window.innerWidth ;
        this.setState({left: left});
        console.log(this.state.selectedPhotography.slikeUGaleriji.length);
    }
    else {
      brslikeuG = 1;
      this.setState({brslikeuG: brslikeuG});
      left = 0 ;
      this.setState({left: left});
    }
  }

  pomeranjeGalerijePhotoLevo = () => {
    let {left} = this.state;
    let {brslikeuG} = this.state;
    if( brslikeuG >= 2 && brslikeuG <= this.state.selectedPhotography.slikeUGaleriji.length){
        brslikeuG -= 1;
        this.setState({brslikeuG: brslikeuG});
        left += window.innerWidth ;
        this.setState({left: left});
    }
    else {
      brslikeuG = this.state.selectedPhotography.slikeUGaleriji.length;
      this.setState({brslikeuG: brslikeuG});
      left = -(window.innerWidth*(this.state.selectedPhotography.slikeUGaleriji.length-1)) ;
      this.setState({left: left});
    }
  }


  pomeranjeFilmGalerijeLevo = () => {
    let {left} = this.state;
    let {brslikeuG} = this.state;
    if( brslikeuG >= 1 && brslikeuG <= this.state.selectedFilm.slikeUGaleriji.length){
        brslikeuG -= 1;
        this.setState({brslikeuG: brslikeuG});
        left += window.innerWidth ;
        this.setState({left: left});
    }
    else {
      brslikeuG = this.state.selectedFilm.slikeUGaleriji.length;
      this.setState({brslikeuG: brslikeuG});
      left = -(window.innerWidth*(this.state.selectedFilm.slikeUGaleriji.length)) ;
      this.setState({left: left});
    }
  }

  pomeranjeFilmGalerijeDesno = () => {
    let {left} = this.state;
    let {brslikeuG} = this.state;
    if( brslikeuG >= 0 && brslikeuG <= this.state.selectedFilm.slikeUGaleriji.length-1){
        brslikeuG += 1;
        this.setState({brslikeuG: brslikeuG});
        left -= window.innerWidth ;
        this.setState({left: left});
        console.log(this.state.selectedFilm.slikeUGaleriji.length);
    }
    else {
      brslikeuG = 0;
      this.setState({brslikeuG: brslikeuG});
      left = 0 ;
      this.setState({left: left});
    }
  }

  resetBrSlika = () => {
    let {brslikeuG} = this.state;
    let {left} = this.state;
    left = 0;
    brslikeuG = 0;
    this.setState({brslikeuG: brslikeuG});
    this.setState({left: left});
  }

  openAboutContactMob = () => {
    let {brslikeuG} = this.state;

    if (brslikeuG === 0){
      const {navKlasa} = this.state;
      navKlasa.push("mobile_remove");
      this.setState({navKlasa: navKlasa});

      var {infoMobile} = this.state;
      infoMobile = ["fas", "fa-times-circle", "info_mobile"];
      this.setState({infoMobile: infoMobile});

      const {aboutContact} = this.state;
      aboutContact.push("open_mob_nav");
      this.setState({aboutContact: aboutContact});

      brslikeuG = 1;
      this.setState({brslikeuG: brslikeuG});
    }
    else {
      brslikeuG = 0;
      this.setState({brslikeuG: brslikeuG});

      var {infoMobile} = this.state;
      infoMobile = ["fas", "fa-info-circle", "info_mobile"];
      this.setState({infoMobile: infoMobile});

      const {aboutContact} = this.state;
      aboutContact.pop();
      this.setState({aboutContact: aboutContact});

      const {navKlasa} = this.state;
      navKlasa.pop();
      this.setState({navKlasa: navKlasa});
    }
  }

  render() {

    return (
      <div>
      <div className="mobile_info">
      <p>We are still working on a mobile version of this portfolio, please open the site on a larger screen.</p>
      </div>
      <div className="all_content">
        <div className={this.state.navRotation.join(' ')}>
        <div className="film_c">
          {this.state.filmovi.map((film, index) => {
            return <Film
                      naslov={film.naslov}
                      naslovnaSlika={film.naslovnaSlika}
                      naslovKlasa={film.naslovKlasa}
                      godina={film.godina}
                      key={film.id}
                      slikaKlasa={film.slikaKlasa}
                      openFilm={() => this.injectFilm(index)}/>
                    })}
        </div>
        <div className="photography_c">
          {this.state.fotografije.map((photography, index) => {
            return  <Gallery
                        naslov={photography.naslov}
                        naslovnaSlika={photography.naslovnaSlika}
                        naslovKlasa={photography.naslovKlasa}
                        brSlika={photography.brSlika}
                        key={photography.id}
                        slikaKlasa={photography.slikaKlasa}
                        openPhotography={() => this.injectPhotography(index)} />
                      })}
        </div>
        <div className="theatre_c">
          {this.state.theatre.map((theatre, index) => {
            return  <Theatre
                        naslov={theatre.naslov}
                        naslovnaSlika={theatre.naslovnaSlika}
                        naslovKlasa={theatre.naslovKlasa}
                        godina={theatre.godina}
                        key={theatre.id}
                        slikaKlasa={theatre.slikaKlasa}
                        openTheatre={() => this.injectTheatre(index)} />
                      })}
        </div>
        <div className="awards_c">
          <Awards />
        </div>
        </div>
        <div className="mobile_nav_bckg"><i onClick={this.openAboutContactMob} className={this.state.infoMobile.join(' ')}></i></div>
        <Navigation
          navRotation={this.state.navRotation}
          rotationFilm={this.filmNav}
          rotationPhotography={this.photographyNav}
          rotationTheatre={this.theatreNav}
          rotationAwards={this.awardsNav}
          navKlasa={this.state.navKlasa}/>
        <ContainerMG
          selectedFilm={this.state.selectedFilm}
          klasa={this.state.updown}
          zatvori={this.closePreview}
          openFilmGallery={this.openFilmGallery}/>
        <ContainerPhotography
          selectedPhotography={this.state.selectedPhotography}
          klasa={this.state.updownPhotography}
          zatvori={this.closePreviewPhotography}
          left={this.state.left}
          brSlike={this.state.brslikeuG}
          klikDesno={this.pomeranjeGalerijePhotoDesno}
          klikLevo={this.pomeranjeGalerijePhotoLevo}/>

        <ContainerFilmGallery
          selectedFilm={this.state.selectedFilm}
          klasa={this.state.updownFilmGallery}
          zatvori={this.closePreviewFilmGallery}
          left={this.state.left}
          brSlike={this.state.brslikeuG}
          klikDesno={this.pomeranjeFilmGalerijeDesno}
          klikLevo={this.pomeranjeFilmGalerijeLevo}/>

        <ContainerTheatre
          selectedTheatre={this.state.selectedTheatre}
          klasa={this.state.updownTheatre}
          zatvori={this.closePreviewTheatre}
          left={this.state.left}
          klikDesno={this.pomeranjeGalerijeDesno}
          klikLevo={this.pomeranjeGalerijeLevo}
          brSlike={this.state.brslikeuG}/>
        <AboutContact
          openContact={this.openContact}
          contactAnim={this.state.contactAnim}
          aboutContact={this.state.aboutContact}
          arrowAnim={this.state.arrowAnim}
          contactbtnAnim={this.state.contactbtnAnim}
          closeContact={this.closeContact}
          whiteCanvasAnim={this.state.whiteCanvasAnim}
          aboutAnim={this.state.aboutAnim}
          openAbout={this.openAbout}
          closeAbout={this.closeAbout}/>
        </div>
      </div>
    );
  }
}

export default App;
