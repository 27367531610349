import React from 'react';
import './containerFilmGallery.css';
import ImageLoopPhoto from '../../components/ImageLoopPhoto/ImageLoopPhoto';

const ContainerFilmGallery = (props) => {
  return (
      <div className={props.klasa.join(' ')}>
      <div className="arrow_holder">
        <p className="br_slike">{props.brSlike}/{props.selectedFilm && props.selectedFilm.slikeUGaleriji && props.selectedFilm.slikeUGaleriji.length}</p>
        <img onClick={props.klikLevo} src="http://lanapavkov.com/content/back_arrow.png" alt="right_arrow" />
        <img onClick={props.klikDesno} src="http://lanapavkov.com/content/back_arrow.png" alt="left_arrow" />
      </div>

      <div className="horizontal_container" style={{ left: props.left }}>
      <div className="horizontal_element film_gallery_flex">
      <div className="film_gallery_text">
        <p className="opis_film_gallery">{props.selectedFilm.opis}</p>
        <div className="horizontalBox_filmGallery">
          <div className="roles_director">
          <p className="rolesH"><b>Credits/</b></p>
          {props.selectedFilm && props.selectedFilm.credits && props.selectedFilm.credits.map((uloga, index)=> {
                   return <p className="uloga">{uloga}</p>
           })}
          </div>
          <div className="roles_director">
          <p className="rolesH"><b>Cast/</b></p>
          {props.selectedFilm && props.selectedFilm.cast && props.selectedFilm.cast.map((director, index)=> {
                   return <p className="uloga">{director}</p>
           })}
          </div>
        </div>
        <p className="use_gallery film_gallery_w"><i>use arrows to see the gallery</i></p>
      </div>
      <div className="film_gallery_poster">
        <img src="http://lanapavkov.com/content/safarikova_img/mala_saf.jpg" alt="safarikova_poster" />
      </div>

      </div>
      {props.selectedFilm && props.selectedFilm.slikeUGaleriji && props.selectedFilm.slikeUGaleriji.map((slika, index)=> {
               return <ImageLoopPhoto Key={index} url={slika} />
       })}
      </div>

        <img onClick={props.zatvori} src="http://lanapavkov.com/content/close.png" alt="close" className="close-popup" />
      </div>
  )
}

export default ContainerFilmGallery;
