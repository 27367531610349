import React from 'react';
import './awards.css';

const Awards = (props) => {
  return (
      <div className="awards">
        <div className="section_festivals">
          <h5>SUCCESS</h5>
          <h6>Pula Film Festival</h6>
          <p>19 Schaffarik Street<span>Pula, 2019.</span></p>

          <h6>FEST</h6>
          <p>19 Schaffarik Street<span>Belgrade, 2019.</span></p>

          <h6>East Doc Platform</h6>
          <p>World Champion<span>Prague, 2017.</span></p>

          <h6>East Silver Doc</h6>
          <p>World Champion<span>Jihlava, 2016.</span></p>

          <h6>Award at ZFF pitching forum</h6>
          <p>19 Schaffarik Street<span>Zagreb, 2017.</span></p>
        </div>

        <p className="is_always">
        is always followed by
        </p>

        <div className="section_awards">
          <h5>FAILURE</h5>
          <h6>Martovski Film Festival</h6>
          <p>19 Schaffarik Street<span>Belgrade, 2019.</span></p>

          <h6>Bašta Film Festival</h6>
          <p>19 Schaffarik Street<span>Bajina Bašta, 2019.</span></p>

          <h6>Locarno Film Festival</h6>
          <p>19 Schaffarik Street<span>Locarno, 2019.</span></p>

          <h6>Sarajevo Film Festival</h6>
          <p>19 Schaffarik Street<span>Sarajevo, 2019.</span></p>
        </div>

        <div className="awards_failure_txt">
        <p>
        “One does not talk about failure within family, in school and among friends; failure is punished and despised. Failure becomes something we repress and are ashamed of… However, failure, which is ubiquitous in our everyday lives, is an indispensable part of our way to the success we want to achieve. Success is an offspring of common experience that comes from a series of failures that happened before it. “
        <br/><br/>
        Goethe Instiute Series, Failure as practice, 2018. pp. 37-38 Failure? Failure! By Zorica Milisavljević
        </p>
        </div>
      </div>
  )
}

export default Awards;
