import React from 'react';
import './aboutcontact.css';

const AboutContact = (props) => {
  return (
      <div>
        <div className={props.aboutContact.join(' ')}>
          <p onClick={props.openAbout}>About</p>
          <div onClick={props.closeContact} className={props.whiteCanvasAnim.join(' ')}></div>
          <div className="contact_arr">
            <p onClick={props.openContact} className={props.contactbtnAnim.join(' ')}>Contact</p>
            <img onClick={props.closeContact} className={props.arrowAnim.join(' ')} src="http://lanapavkov.com/content/back_arrow.png" alt="close-contact" />
          </div>
        </div>

        <div className={props.contactAnim.join(' ')}>
          <img onClick={props.closeContact} className="mobile_close_contact" src="http://lanapavkov.com/content/back_arrow.png" alt="close-contact" />
          <p><b>Lana Pavkov</b></p>
          <p className="proffesion"><b>Theatre and film director</b></p>
          <p>
            MA University of Belgrade,<br />
            Faculty of Dramatic Arts<br />
            BA University of Novi Sad,<br />
            Academy of Arts
          </p>
          <p className="malena_lajna"></p>
          <a href="tel:+381062590511">+381 (0) 62 590 511</a>
          <a href="mailto:lana.pavkov@gmail.com">lana.pavkov@gmail.com</a>
          <p className="copyright">Srđan Šuša © 2019.</p>
          <div className="social_network">
            <a href="https://vimeo.com/lanapavkov" target="_blank"><i className="fab fa-vimeo-v"></i></a>
            <a href="https://www.instagram.com/lankkkka/" target="_blank"><i className="fab fa-instagram"></i></a>
            <a href="https://rs.linkedin.com/in/lana-pavkov-b4920b169" target="_blank"><i className="fab fa-linkedin-in"></i></a>
          </div>
        </div>

        <div onClick={props.closeAbout} className={props.aboutAnim.join(' ')}>
          <div className="img_holder"></div>
          <div className="about_text_segment">
            <div className="fax_about">
              <p><b>BA Theatre directing - Academy of Arts</b><br />NOVI SAD / 2012 - 16</p>
              <p><b>MA Film and TV directing - Faculty of Dramatic Arts</b><br />BELGRADE / 2016 - 18</p>
            </div>
            <div className="text_text">
              <p>
              After the first unsuccessful attempt to get into Faculty of Dramatic Arts Lana took a gap year and travelled to London. There she volunteered for Soho Theatre as an assistant set designer. After third attempt, she started her studies of multimedia directing in class of Nikita Milivojević. It was a mix class with actors. During this time she took part in various of acting and directing workshops such as Scott Fielding’s Michael Chekhov Method (Actors Studio, Boston); Katharina Seyfhert’s Grotowski Lab; British Film Institute Project Development Lab, Zagreb Dox pitching forum, etc. Lana graduated with a play Dear Elena Sergeevna in Novi Sad, at Youth Theatre. The play was banned only after a couple of months.
              </p>
              <p>
              In 2016 she engaged assisting professors at Faculty of Technical Sciences on Stage Design department for a project called Scen_Lab Archive. The same year she started voluntary work for international movie database MUBI. Recently she engaged Goethe Institute’s new project called Lie and Theft working with Darko Dragičević.
              </p>
            </div>
          </div>
          <img src="http://lanapavkov.com/content/close_w.png" alt="close_about" className="close_about" />
        </div>
      </div>
  )
}

export default AboutContact;
